import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'

import Modal from '../../../SharedComponents/Modal/Modal'
import SelectInput from '../../../SharedComponents/SelectInput/SelectInput'
import TextInput from '../../../SharedComponents/TextInput/TextInput'

const nigerianBanks = [
  { label: 'Access Bank', value: 'access-bank' },
  { label: 'Citibank Nigeria', value: 'citibank-nigeria' },
  { label: 'Ecobank Nigeria', value: 'ecobank-nigeria' },
  { label: 'Fidelity Bank', value: 'fidelity-bank' },
  { label: 'First Bank of Nigeria', value: 'first-bank-of-nigeria' },
  {
    label: 'First City Monument Bank (FCMB)',
    value: 'first-city-monument-bank'
  },
  { label: 'Globus Bank', value: 'globus-bank' },
  {
    label: 'Guaranty Trust Bank (GTBank)',
    value: 'guaranty-trust-bank'
  },
  { label: 'Heritage Bank', value: 'heritage-bank' },
  { label: 'Keystone Bank', value: 'keystone-bank' },
  { label: 'Polaris Bank', value: 'polaris-bank' },
  { label: 'PremiumTrust Bank', value: 'premiumtrust-bank' },
  { label: 'Providus Bank', value: 'providus-bank' },
  { label: 'Stanbic IBTC Bank', value: 'stanbic-ibtc-bank' },
  {
    label: 'Standard Chartered Bank Nigeria',
    value: 'standard-chartered-bank-nigeria'
  },
  { label: 'Sterling Bank', value: 'sterling-bank' },
  { label: 'SunTrust Bank', value: 'suntrust-bank' },
  { label: 'Titan Trust Bank', value: 'titan-trust-bank' },
  { label: 'Union Bank of Nigeria', value: 'union-bank-of-nigeria' },
  {
    label: 'United Bank for Africa (UBA)',
    value: 'united-bank-for-africa'
  },
  { label: 'Unity Bank', value: 'unity-bank' },
  { label: 'Wema Bank', value: 'wema-bank' },
  { label: 'Zenith Bank', value: 'zenith-bank' }
]

const CustomModal = ({ isOpen, onClose, title, onSubmit, children }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-white rounded-lg shadow-lg p-6 relative'>
        <button
          className='absolute top-2 right-2 text-gray-400 hover:text-gray-800'
          onClick={onClose}
        >
          ✖
        </button>
        <h2 className='text-lg font-bold mb-4 text-center'>{title}</h2>
        <form onSubmit={onSubmit}>{children}</form>
      </div>
    </div>
  )
}

const ProfileCardDetails = () => {
  const { user } = useAuth()
  const [cards, setCards] = useState([])
  const [banks, setBanks] = useState([])
  const [isCardModalOpen, setCardModalOpen] = useState(false)
  const [isBankModalOpen, setBankModalOpen] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false
  })
  const [isModalOpen, setModalOpen] = useState(false)

  const { mutate: fetchCards } = useBackendService('/cards', 'get', {
    onSuccess: (response) => {
      setCards(response.data)
    },
    onError: (error) => {}
  })

  const { mutate: fetchBanks } = useBackendService('/banks', 'get', {
    onSuccess: (response) => {
      setBanks(response.data)
    },
    onError: (error) => {}
  })

  useEffect(() => {
    if (user?.id) {
      fetchCards({ user_id: user.id })
      fetchBanks({ user_id: user.id })
    }
  }, [user, fetchCards, fetchBanks])

  const formatCardNumber = (number) => {
    const strNumber = number.toString()
    return `**** **** **** ${strNumber.slice(-4)}`
  }
  const reorderedCards = [
    ...cards.filter((card) => card.is_default),
    ...cards.filter((card) => !card.is_default)
  ]

  const defaultCard = cards.find((card) => card.is_default)
  const defaultBank = banks.find((bank) => bank.is_default)

  const nonDefaultCards = cards.filter((card) => !card.is_default)
  const nonDefaultBanks = banks.filter((bank) => !bank.is_default)

  const {
    register: bankRegister,
    handleSubmit: handleBankSubmit,
    control: bankControl,
    formState: {
      errors: bankErrors,
      isDirty: isBankDirty,
      isValid: isBankValid
    },
    reset: bankReset
  } = useForm()

  const {
    register: cardRegister,
    handleSubmit: handleCardSubmit,
    formState: {
      errors: cardErrors,
      isDirty: isCardDirty,
      isValid: isCardValid
    },
    reset: cardReset
  } = useForm()

  const { mutate: addBank, isLoading } = useBackendService('/banks', 'post', {
    onSuccess: (data) => {
      setModal({
        type: 'success',
        title: 'Bank Account Added',
        desc: 'Great! You have successfully added a new bank account.',
        link: '/profile',
        visible: true
      })

      setModalOpen(true)
    },
    onError: () => {
      setModal({
        type: 'error',
        title: 'Bank Account Error',
        desc: 'Oops! There was an issue adding your bank account. Please try again.',
        visible: true
      })
      setModalOpen(true)
    }
  })

  const { mutate: linkCard, isLoading: cardLoading } = useBackendService(
    '/cards',
    'post',
    {
      onSuccess: (data) => {
        setModal({
          type: 'success',
          title: 'Card Added',
          desc: 'Awesome! You have successfully added a new card.',
          link: '/profile',
          visible: true
        })

        setModalOpen(true)
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Card Addition Failed',
          desc: 'Something went wrong while adding your card. Please check your details and try again.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const onBankSubmit = (data) => {
    const bankData = {
      ...data,
      isDefault: true
    }

    addBank(bankData)
    bankReset()
    setBankModalOpen(false)
  }

  const onCardSubmit = (data) => {
    const cardData = {
      ...data,
      isDefault: false
    }

    linkCard(cardData)
    cardReset()
    setCardModalOpen(false)
  }

  const getBankNameFromSlug = (slug) => {
    const bank = nigerianBanks.find((bank) => bank.value === slug)
    return bank ? bank.label : slug
  }

  const [isPopupOpen, setPopupOpen] = useState(false)
  const [isPopupOpen2, setPopupOpen2] = useState(false)

  const [selectedCardId, setSelectedCardId] = useState(null)
  const [selectedBankId, setSelectedBankId] = useState(null)

  const togglePopup = (type, id) => {
    if (type === 'card') {
      setSelectedCardId(id)
      setPopupOpen((prev) => !prev)
    } else {
      setSelectedBankId(id)
      setPopupOpen2((prev) => !prev)
    }
  }

  const deleteCard = (id) => {
    deleteCardapi()
    setPopupOpen(false)
  }
  const updateDefaultCard = (id) => {
    setUpdateModal(true)
  }
  const handleYesChange = () => {
    updateDefault()
    setUpdateModal(false)
  }
  const handleNoChange = () => {
    setUpdateModal(false)
  }

  const deleteBank = (id) => {
    deleteBankapi()
    setPopupOpen2(false)
  }

  const { mutate: updateDefault } = useBackendService(
    `/cards/${selectedCardId}`,
    'put',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Default card updated',
          desc: 'Your card has been successfully updated.',
          link: '/profile',
          visible: true
        })
        setModalOpen(true)
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Card update Failed',
          desc: 'Something went wrong while updating your default card. Please try again.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const { mutate: deleteCardapi } = useBackendService(
    `/cards/${selectedCardId}`,
    'delete',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Card Deleted',
          desc: 'Your card has been successfully deleted.',
          link: '/profile',

          visible: true
        })
        setModalOpen(true)
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Card Deletion Failed',
          desc: 'Something went wrong while deleting your card. Please try again.',

          visible: true
        })
        setModalOpen(true)
      }
    }
  )

  const { mutate: deleteBankapi } = useBackendService(
    `/banks/${selectedBankId}`,
    'delete',
    {
      onSuccess: () => {
        setModal({
          type: 'success',
          title: 'Bank Deleted',
          desc: 'Your bank account has been successfully deleted.',
          link: '/profile',
          visible: true
        })
        setModalOpen(true)
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Bank Deletion Failed',
          desc: 'Something went wrong while deleting your bank account. Please try again.',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )
  const getSelectedCard = () => {
    return cards.find((card) => card.id === selectedCardId)
  }
  return (
    <div className='border border-gray-300 rounded-md w-full h-full'>
      <div>
        <h1 className='font-medium pl-3 pt-3'>Withdrawal Bank</h1>
        <h1 className='text-gray-600 pl-3 pb-1'>
          This is where the funds from your investments would be sent to once
          it’s due.
        </h1>
        <hr />

        <div className='w-[100%] p-3 space-y-4'>
          {!defaultBank && !nonDefaultBanks.length && (
            <div className='flex flex-col items-center'>
              <div className='mt-3'>
                <svg
                  width='231'
                  height='161'
                  viewBox='0 0 131 61'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.5183 30.9508C10.5183 33.7918 8.23365 36.0905 5.42069 36.0905C2.60773 36.0905 0.32311 33.7918 0.32311 30.9508C0.32311 28.1099 2.60773 25.8111 5.42069 25.8111C8.23365 25.8111 10.5183 28.1099 10.5183 30.9508Z'
                    fill='#F1F1F1'
                    stroke='#232221'
                    strokeWidth='0.646219'
                  />
                  <rect
                    x='2.25879'
                    y='3.64038'
                    width='95.7655'
                    height='10.9256'
                    rx='5.46279'
                    fill='#F1F1F1'
                  />
                  <rect
                    x='35.2344'
                    y='25.488'
                    width='95.7655'
                    height='10.9256'
                    rx='5.46279'
                    fill='#F1F1F1'
                  />
                  <rect
                    x='17.166'
                    y='47.7966'
                    width='65.9517'
                    height='10.9256'
                    rx='5.46279'
                    fill='#F1F1F1'
                  />
                  <rect
                    x='1.16023'
                    y='0.725737'
                    width='56.8085'
                    height='37.1962'
                    rx='4.20043'
                    transform='matrix(0.974092 -0.226151 0.222853 0.974852 2.12712 13.5656)'
                    fill='#F3F3F3'
                    stroke='#999999'
                    strokeWidth='1.93866'
                  />
                  <rect
                    x='33.9449'
                    y='22.8167'
                    width='56.7855'
                    height='37.2113'
                    rx='4.20043'
                    fill='#F3F3F3'
                    stroke='#999999'
                    strokeWidth='1.93866'
                  />
                  <rect
                    x='34.3311'
                    y='32.3201'
                    width='56.0138'
                    height='8.19418'
                    fill='#DDDDDD'
                  />
                  <path
                    d='M34.3311 32.3201H55.5621M57.5948 32.3201H62.7897M64.8224 32.3201H85.6017M90.119 40.5143H75.2121M70.0173 40.5143H44.269'
                    stroke='#999999'
                    strokeWidth='1.93866'
                    strokeLinecap='round'
                  />
                  <path
                    d='M108.543 53.2594C108.543 56.1003 106.258 58.3991 103.445 58.3991C100.632 58.3991 98.3475 56.1003 98.3475 53.2594C98.3475 50.4185 100.632 48.1197 103.445 48.1197C106.258 48.1197 108.543 50.4185 108.543 53.2594Z'
                    fill='#F1F1F1'
                    stroke='#232221'
                    strokeWidth='0.646219'
                  />
                  <ellipse
                    cx='114.286'
                    cy='9.10317'
                    rx='5.42069'
                    ry='5.46279'
                    fill='#F1F1F1'
                  />
                </svg>
              </div>
              <h1 className='p-3 m-3'>
                You don’t have a withdrawal account set up yet
              </h1>
              <div
                className='w-[15vw] mb-3 cursor-pointer p-2  flex gap-2 justify-start items-center  h-[50px] bg-[#144618] bg-opacity-10 rounded-md ts'
                onClick={() => setBankModalOpen(true)}
              >
                {isLoading ? (
                  <div className='m-auto border-4 border-t-darkGreen border-opacity-25 rounded-full w-6 h-6 animate-spin' />
                ) : (
                  <h1 className='text-darkGreen m-auto'>Add new Bank</h1>
                )}
              </div>
            </div>
          )}

          {defaultBank && (
            <>
              <div className='w-[80%] rounded-md p-3 flex justify-between items-start ts'>
                <div className='w-[60%] flex flex-col justify-center items-start'>
                  <div className='relative bg-[#144618] w-96 h-56 rounded-lg shadow-lg p-6 flex flex-col justify-between'>
                    <div className='flex justify-between items-center'>
                      <div className='text-white'>
                        {getBankNameFromSlug(defaultBank.bank_name)}
                      </div>
                      <div className='flex'>
                        <h2 className='text-white text-sm font-light'>
                          Default
                        </h2>
                        <button
                          className='text-white'
                          onClick={() => togglePopup('bank', defaultBank.id)}
                        >
                          ▼
                        </button>
                      </div>
                      {isPopupOpen2 && selectedBankId === defaultBank.id && (
                        <div className='absolute flex flex-col mt-2 bg-white left-[200px] p-2 rounded-lg shadow-lg'>
                          <button
                            className='text-red-500 mt-2'
                            onClick={() => deleteBank(defaultBank.id)}
                          >
                            Delete
                          </button>
                          <button
                            className='text-gray-600 mt-2'
                            onClick={() => setPopupOpen(false)}
                          >
                            Close
                          </button>
                        </div>
                      )}
                    </div>

                    <div className='flex-1' />

                    <div>
                      <h1 className='text-white font-medium text-xl'>
                        {defaultBank.account_number}
                      </h1>
                      <div className='flex justify-between mt-4'>
                        <div>
                          <h1 className='text-white'>
                            {defaultBank.account_name}
                          </h1>
                        </div>
                        <div />
                      </div>
                    </div>
                    <div className='z-20 absolute bottom-0 right-0 w-24 h-24 bg-[#69B815] border-2 border-[#ADEE68] rounded-tl-full' />
                  </div>

                  <div
                    className='w-[20vw] mt-5 mb-3 cursor-pointer p-2  flex gap-2 justify-start items-center h-[50px] bg-[#144618] bg-opacity-10 rounded-md ts'
                    // onClick={() => setBankModalOpen(true)}
                  >
                    <h1 className='text-darkGreen m-auto'>
                      Change withdrawal bank
                    </h1>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div>
        <hr />
        <h1 className='font-medium pl-3 pt-3'>Linked Card</h1>
        <h1 className='text-gray-600 pl-3 pb-1'>
          This is where your monthly subscription would be deducted from.
        </h1>
        <hr />

        <div className='w-[100%] p-3 space-y-4'>
          {!defaultCard && !nonDefaultCards.length && (
            <div className='flex flex-col items-center'>
              <div className='mt-3'>
                <svg
                  width='231'
                  height='161'
                  viewBox='0 0 131 61'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.5183 30.9508C10.5183 33.7918 8.23365 36.0905 5.42069 36.0905C2.60773 36.0905 0.32311 33.7918 0.32311 30.9508C0.32311 28.1099 2.60773 25.8111 5.42069 25.8111C8.23365 25.8111 10.5183 28.1099 10.5183 30.9508Z'
                    fill='#F1F1F1'
                    stroke='#232221'
                    strokeWidth='0.646219'
                  />
                  <rect
                    x='2.25879'
                    y='3.64038'
                    width='95.7655'
                    height='10.9256'
                    rx='5.46279'
                    fill='#F1F1F1'
                  />
                  <rect
                    x='35.2344'
                    y='25.488'
                    width='95.7655'
                    height='10.9256'
                    rx='5.46279'
                    fill='#F1F1F1'
                  />
                  <rect
                    x='17.166'
                    y='47.7966'
                    width='65.9517'
                    height='10.9256'
                    rx='5.46279'
                    fill='#F1F1F1'
                  />
                  <rect
                    x='1.16023'
                    y='0.725737'
                    width='56.8085'
                    height='37.1962'
                    rx='4.20043'
                    transform='matrix(0.974092 -0.226151 0.222853 0.974852 2.12712 13.5656)'
                    fill='#F3F3F3'
                    stroke='#999999'
                    strokeWidth='1.93866'
                  />
                  <rect
                    x='33.9449'
                    y='22.8167'
                    width='56.7855'
                    height='37.2113'
                    rx='4.20043'
                    fill='#F3F3F3'
                    stroke='#999999'
                    strokeWidth='1.93866'
                  />
                  <rect
                    x='34.3311'
                    y='32.3201'
                    width='56.0138'
                    height='8.19418'
                    fill='#DDDDDD'
                  />
                  <path
                    d='M34.3311 32.3201H55.5621M57.5948 32.3201H62.7897M64.8224 32.3201H85.6017M90.119 40.5143H75.2121M70.0173 40.5143H44.269'
                    stroke='#999999'
                    strokeWidth='1.93866'
                    strokeLinecap='round'
                  />
                  <path
                    d='M108.543 53.2594C108.543 56.1003 106.258 58.3991 103.445 58.3991C100.632 58.3991 98.3475 56.1003 98.3475 53.2594C98.3475 50.4185 100.632 48.1197 103.445 48.1197C106.258 48.1197 108.543 50.4185 108.543 53.2594Z'
                    fill='#F1F1F1'
                    stroke='#232221'
                    strokeWidth='0.646219'
                  />
                  <ellipse
                    cx='114.286'
                    cy='9.10317'
                    rx='5.42069'
                    ry='5.46279'
                    fill='#F1F1F1'
                  />
                </svg>
              </div>
              <h1 className='p-3 m-3'>
                You don’t have a linked card set up yet
              </h1>

              <div
                className='w-[15vw] mb-3 cursor-pointer p-2  flex gap-2 justify-start items-center  h-[50px] bg-[#144618] bg-opacity-10 rounded-md ts'
                onClick={() => setCardModalOpen(true)}
              >
                {cardLoading ? (
                  <div className='m-auto border-4 border-t-darkGreen border-opacity-25 rounded-full w-6 h-6 animate-spin' />
                ) : (
                  <h1 className='text-darkGreen m-auto'>Link Card</h1>
                )}
              </div>
            </div>
          )}

          {reorderedCards.map((card) => (
            <div
              key={card.id}
              className='w-[80%] rounded-md p-3 flex justify-between items-start ts'
            >
              <div className='w-[60%] flex flex-col justify-center items-start'>
                <div className='relative bg-[#144618] w-96 h-56 rounded-lg shadow-lg p-6 flex flex-col justify-between'>
                  <div className='flex justify-between items-center'>
                    <div>
                      <svg
                        width='39'
                        height='25'
                        viewBox='0 0 39 25'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.8975 2.52197H24.197V21.0582H13.8975V2.52197Z'
                          fill='#FF5F00'
                        />
                        <path
                          d='M14.5503 11.7898C14.5503 8.02368 16.3159 4.68317 19.0296 2.52165C17.0353 0.949702 14.5176 0 11.7711 0C5.26415 0 0 5.27259 0 11.7898C0 18.307 5.26415 23.5796 11.771 23.5796C14.5175 23.5796 17.0351 22.6299 19.0296 21.0578C16.3159 18.9291 14.5503 15.556 14.5503 11.7898Z'
                          fill='#EB001B'
                        />
                        <path
                          d='M38.0921 11.7898C38.0921 18.3069 32.828 23.5796 26.3212 23.5796C23.5746 23.5796 21.057 22.6299 19.0625 21.0578C21.809 18.8965 23.542 15.556 23.542 11.7898C23.542 8.02368 21.7763 4.68317 19.0625 2.52165C21.0569 0.949702 23.5746 0 26.3212 0C32.828 0 38.0921 5.30541 38.0921 11.7898Z'
                          fill='#F79E1B'
                        />
                      </svg>
                    </div>

                    <div className='relative flex'>
                      {card.is_default && (
                        <h2 className='text-white text-sm font-light'>
                          Default
                        </h2>
                      )}
                      <button
                        className='text-white'
                        onClick={() => togglePopup('card', card.id)}
                      >
                        ▼
                      </button>
                    </div>
                    {isPopupOpen && selectedCardId === card.id && (
                      <div className='absolute flex flex-col mt-2 bg-white left-[200px] p-2 rounded-lg shadow-lg'>
                        {!card.is_default && (
                          <button
                            className='text-gray-600 mt-2'
                            onClick={() => updateDefaultCard(card.id)}
                          >
                            Make default
                          </button>
                        )}
                        <button
                          className='text-red-500 mt-2'
                          onClick={() => deleteCard(card.id)}
                        >
                          Delete
                        </button>

                        <button
                          className='text-gray-600 mt-2'
                          onClick={() => setPopupOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    )}
                  </div>

                  <div className='flex-1' />

                  <div>
                    <h1 className='text-white font-medium text-xl'>
                      {formatCardNumber(card.card_number)}
                    </h1>
                    <div className='flex justify-between mt-4'>
                      <div>
                        <h1 className='text-white'>Exp: {card.expiry_date}</h1>
                      </div>
                      <div />
                    </div>
                  </div>
                  <div className='z-20 absolute bottom-0 right-0 w-24 h-24 bg-[#69B815] border-2 border-[#ADEE68] rounded-tl-full' />
                </div>
              </div>
            </div>
          ))}
          <div
            className='w-[20vw] mt-5 mb-3 cursor-pointer p-2  flex gap-2 justify-start items-center h-[50px] bg-[#144618] bg-opacity-10 rounded-md ts'
            onClick={() => setCardModalOpen(true)}
          >
            <h1 className='text-darkGreen m-auto'>Link new card</h1>
          </div>
        </div>
      </div>

      <CustomModal
        isOpen={isBankModalOpen}
        onClose={() => setBankModalOpen(false)}
        title='Add New Bank'
        onSubmit={handleBankSubmit(onBankSubmit)}
      >
        <div className='w-[25vw] flex flex-col gap-2'>
          <SelectInput
            register={bankRegister}
            control={bankControl}
            Controller={Controller}
            title='Bank'
            data={nigerianBanks}
            name='bankName'
          />
          <div className='grid w-full items-center gap-1.5'>
            <label className='font-[100]' htmlFor='accountnumber'>
              Account number
            </label>
            <input
              type='number'
              name='accountNumber'
              placeholder='0000000000000000'
              {...bankRegister('accountNumber', {
                required: 'Account number is required',
                minLength: {
                  value: 9,
                  message: 'Account number must be at least 9 digits'
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Account number must be a number'
                }
              })}
              className='email-input'
              onWheel={(e) => e.target.blur()}
            />

            {bankErrors.accountNumber && (
              <p>{bankErrors.accountNumber.message}</p>
            )}
          </div>

          <TextInput
            className='mb-5'
            register={bankRegister}
            text='Account Name'
            name='accountName'
            errors={bankErrors}
          />
          <button
            type='submit'
            className={`mt-5 w-full h-[50px] ts ${
              isBankDirty && isBankValid
                ? 'proceed-btn bg-darkGreen'
                : 'rounded-[8px] bg-darkash text-black'
            }`}
          >
            Add Bank
          </button>
        </div>
      </CustomModal>

      <CustomModal
        isOpen={isCardModalOpen}
        onClose={() => setCardModalOpen(false)}
        title='Link New Card'
        onSubmit={handleCardSubmit(onCardSubmit)}
      >
        <div className='w-[25vw] flex flex-col gap-2'>
          <div className='grid w-full items-center gap-1.5'>
            <label htmlFor='email'>Card number</label>
            <input
              type='number'
              name='cardNumber'
              placeholder='0000 0000 0000 0000'
              {...cardRegister('cardNumber', {
                required: 'Card number is required',
                minLength: {
                  value: 16,
                  message: 'Card number must be at least 16 digits'
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Card number must be a number'
                }
              })}
              className='email-input'
              onWheel={(e) => e.target.blur()}
            />

            {cardErrors.cardnumber && <p>{cardErrors.cardnumber.message}</p>}
          </div>
          <div className='grid items-center gap-1.5'>
            <label htmlFor='email'>Expiry Date</label>
            <input
              type='text'
              name='expiryDate'
              placeholder='MM/YY'
              {...cardRegister('expiryDate', {
                required: 'Expiry date is required',
                pattern: {
                  value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                  message: 'Invalid expiry date format (MM/YY)'
                }
              })}
              className='email-input'
            />
          </div>
          <div className='grid items-center gap-1.5'>
            <label htmlFor='email'>CVV</label>
            <input
              type='text'
              name='cvv'
              placeholder='123'
              maxLength={3}
              {...cardRegister('cvv', {
                required: 'CVV is required',
                pattern: {
                  value: /^[0-9]{3}$/,
                  message: 'Invalid CVV format (3 digits)'
                }
              })}
              className='email-input'
            />
          </div>
          <TextInput
            className='mb-5'
            register={cardRegister}
            text='Card Holder Name'
            name='cardHolder'
            errors={cardErrors}
          />
          <button
            type='submit'
            className={`mt-5 w-full h-[50px] ts ${
              isCardDirty && isCardValid
                ? 'proceed-btn bg-darkGreen'
                : 'rounded-[8px] bg-darkash text-black'
            }`}
          >
            Link Card
          </button>
        </div>
      </CustomModal>

      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          unlock={true}
          onClose={() => setModalOpen(false)}
        />
      )}
      {updateModal && (
        <Modal
          title='Default card change'
          desc={`Your default card will be changed to ${formatCardNumber(
            getSelectedCard()?.card_number || ''
          )} and will be used for subsequent payments.`}
          type='warning'
          cardmultiplebtn={true}
          isOpen={updateModal}
          onYes={handleYesChange}
          onClose={handleNoChange}
        />
      )}
    </div>
  )
}

export default ProfileCardDetails
