import React from 'react'
import { Link } from 'react-router-dom'
import useWindowSize from '../../../../farmapp/components/EnterpriseApp/UseWindowSize/UseWindowSize'
import BlurFade from '../../../components/Animations/BlurFade'

const TgaimsWorkSection = () => {
  const { width } = useWindowSize()

  // Data for cards
  const cardsData = [
    {
      title: 'Sign Up',
      description: 'Create an account and set up your personal and business profile. Enter details about operation.',
      icon: require('../../../assets/icon.png')
    },
    {
      title: 'Monitor',
      description: 'Track real-time data from your farm. Use our intuitive dashboard to visualize key metrics and receive alerts.',
      icon: require('../../../assets/icon-1.png')
    },
    {
      title: 'Grow',
      description: 'Watch your agri investments and business thrive with improved efficiency. For lower cost and apply sustainable practices.',
      icon: require('../../../assets/icon-2.png')
    },
    {
      title: 'Integrate',
      description: 'Connect your existing tools and systems to our platform. Our seamless integration process makes it easy.',
      icon: require('../../../assets/icon-3.png')
    },
    {
      title: 'Optimize',
      description: 'Use our advanced tools to optimize resource usage. Implement sustainable practice and boost investment returns.',
      icon: require('../../../assets/icon-4.png')
    }
  ]

  return (
    <section className='lg:px-[100px] px-[5%] py-10 lg:py-[64px] gap-10 flex flex-col'>
      <div className='flex flex-col text-center gap-3'>
        <BlurFade inView delay={0.4} yOffset='20px'>
          <p className='font-[600] lg:font-[600] text-[18px] lg:text-[30px] text-start lg:text-center text-primary'>
            How T-Gaims Works
          </p>
        </BlurFade>
        <BlurFade inView delay={0.8} yOffset='22px'>
          <p className='text-grey lg:text-[16px] text-[13px] text-start lg:text-center'>
            Whether you're overseeing agriculture investment portfolios, businesses
            (large, medium or small scale enterprise),
            <br className='hidden lg:flex' />
            our features ensure you have everything you need to succeed.
          </p>
        </BlurFade>
      </div>

      <div className='flex flex-col lg:items-center lg:justify-center lg:flex-row lg:gap-40 gap-10 lg:mt-6'>
        <div className='w-full lg:w-1/2'>
          {width > 1024
            ? (
              <img
                src={require('../../../assets/Rectangle 216.png')}
                alt=''
                className='w-full h-full object-contain'
              />
              )
            : (
              <img
                src={require('../../../assets/plantmobile.png')}
                alt=''
                className='w-full h-full object-contain'
              />
              )}
        </div>

        <div className='flex flex-col justify-start items-end w-full lg:w-1/2 text-start gap-[50px] lg:gap-[90px]'>
          {/* Cards mapped from data */}
          <div className='flex flex-col gap-5'>
            {cardsData.map((card, index) => (
              <BlurFade
                key={index}
                inView
                xOffset={100}
                blur='8px'
                delay={index * 0.35}
                duration={0.6}
              >
                <div className='flex flex-row gap-5'>
                  <img
                    src={card.icon}
                    alt={`${card.title} icon`}
                    className='lg:w-[40px] w-[30px] h-[30px] lg:h-[40px]'
                  />
                  <div className='w-full gap-1 lg:gap-2 flex flex-col'>
                    <p className='font-[500] lg:text-[18px] text-[14px] text-black'>{card.title}</p>
                    <p className='font-[400] lg:text-[16px] text-[13px] text-grey'>{card.description}</p>
                  </div>
                </div>
              </BlurFade>
            ))}
          </div>

          <BlurFade delay={0.40 * 3} blur='8px' inView className='w-full'>
            <Link to='/signup' className='w-full mt-8'>
              <div className='lg:py-[10px] center h-[53px] text-center lg:mb-0 mb-20 p-2 lg:px-6 bg-primary rounded-lg cursor-pointer'>
                <p className='font-[500] text-darkGreen text-[14px] lg:text-[18px]'>Get started now</p>
              </div>
            </Link>
          </BlurFade>
        </div>
      </div>
    </section>
  )
}

export default TgaimsWorkSection
