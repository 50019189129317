import { ArrowLeft } from 'lucide-react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useBackendService from '../../../../../config/services/backend-service'
import Modal from '../../../SharedComponents/Modal/Modal'
import NavigvationSteps from '../NavigationSteps/NavigvationSteps'
import Catalog from './Catalog'
import Products from './Products'
import Sales from './Sales'

function AddNewProductCatalog() {
  const [stage, setStage] = useState(1)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modal, setModal] = useState({
    type: '',
    title: '',
    desc: '',
    link: '',
    visible: false // Add a visible property for modal
  })
  const [images, setImages] = useState([])
  const [location, setLocation] = useState([])

  const handleNextStage = () => {
    if (stage < 3) {
      setStage(stage + 1)
    }
  }
  const handlePrevStage = () => {
    if (stage > 1) {
      setStage(stage - 1)
    } else setStage(1)
  }
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid, isDirty }
  } = useForm({ mode: 'onChange' })
  const { mutate: createProductCatalog, isLoading } = useBackendService(
    '/product_catalogues',
    'post',
    {
      onSuccess: () => {
        setModal({
          title: 'Product Catalog added',
          desc: 'Well done , you have successfully created a new product catalog',
          type: 'success',
          link: '/admin-products',
          visible: true
        })
        setModalOpen(true)
      },
      onError: () => {
        setModal({
          type: 'error',
          title: 'Submission failed',
          desc: 'You are unable to create a product catalog',
          visible: true
        })
        setModalOpen(true)
      }
    }
  )
  const onSubmit = async (data) => {
    console.log(data)
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('category', data.category)
    formData.append('description', data.description)
    formData.append(
      'nutritionMarketValue',
      JSON.stringify(data.nutritionMarketValue)
    )

    formData.append('growingTips', JSON.stringify(data.growingTips))
    formData.append('careInstructions', JSON.stringify(data.careInstructions))

    formData.append('storageSolutions', data.storageSolutions)
    formData.append('packagingMethods', data.packagingMethods)
    formData.append('marketPrices', JSON.stringify(data.marketPrices))
    formData.append('logisticsProviders', data.logisticsProviders)
    formData.append('salesChannels', data.salesChannels)
    location.forEach((location, index) => {
      formData.append(`locations[${index}]`, location)
    })
    images.forEach((image, index) => {
      formData.append('images', image.file)
    })
    createProductCatalog(formData)
  }

  return (
    <section className=' p-5 lg:p-10 flex flex-col gap-[15px] lg:gap-[30px] bg-white '>
      <div className=' mb-2 lg:mb-4 '>
        <a href='/admin-products'>
          <ArrowLeft size={24} />
        </a>
      </div>
      <div className='space-y-0 lg:space-y-2 '>
        <h1 className='text-[16px] lg:text-xl text-black'>
          Add product catalog
        </h1>
        <p className='text-[#666666] text-sm lg:text-sm'>
          Enter product details to add
        </p>
      </div>
      <NavigvationSteps stage={stage} />
      <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
        {stage === 1 && (
          <Products
            onClick={handleNextStage}
            register={register}
            errors={errors}
            isValid={isValid}
            control={control}
            setImages={setImages}
            images={images}
            location={location}
            setLocation={setLocation}
          />
        )}
        {stage === 2 && (
          <Catalog
            handleNext={handleNextStage}
            handlePrev={handlePrevStage}
            register={register}
            errors={errors}
            isValid={isValid}
          />
        )}
        {stage === 3 && (
          <Sales
            handlePrev={handlePrevStage}
            register={register}
            errors={errors}
            isValid={isValid}
            isLoading={isLoading}
            isDirty={isDirty}
          />
        )}
      </form>
      {modal.visible && (
        <Modal
          type={modal.type}
          title={modal.title}
          desc={modal.desc}
          link={modal.link}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </section>
  )
}

export default AddNewProductCatalog
