import { X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../config/contexts/authProvider'
import useBackendService from '../../../../../config/services/backend-service'
import TextInput from '../../../SharedComponents/TextInput/TextInput'

function ModalForUserInvestAccount({ isOpen, onClose, units, price, id }) {
  const { user } = useAuth()
  const paystackPublicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  const [cards, setCards] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [showCardFields, setShowCardFields] = useState(false)

  const fetchCards = useBackendService('/cards', 'get', {
    onSuccess: (response) => setCards(response.data),
    onError: () => toast.error('Failed to fetch cards.')
  }).mutate

  const processInvestment = useBackendService(
    '/investments/users_investment',
    'post'
  ).mutate

  const saveRecurring = useBackendService(
    '/ipn/paystack/save_recurring',
    'post'
  ).mutate

  const processRecurringPayment = useBackendService(
    '/ipn/paystack/charge_recurring',
    'post',
    {
      onSuccess: () => {
        handleInvestmentProcessing('Payment successful!')
      },
      onError: (error) => handleRecurringPaymentError(error)
    }
  ).mutate

  const saveInvestmentPayment = useBackendService(
    '/ipn/paystack/investment_paystack',
    'post',
    {
      onSuccess: () => {
        handleInvestmentProcessing('Payment successful!')
      },
      onError: () => toast.error('Recurring payment failed. Please try again.')
    }
  ).mutate

  useEffect(() => {
    if (user?.id) fetchCards({ user_id: user.id })
  }, [user, fetchCards])

  useEffect(() => {
    if (selectedOption === 'add_card') {
      setShowCardFields(true)
      resetCardFields()
    } else if (selectedOption?.startsWith('card_')) {
      setShowCardFields(false)
      populateCardFields(selectedOption)
    } else {
      setShowCardFields(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, cards])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty, isValid }
  } = useForm({ mode: 'onChange' })

  const unit = watch('unit', 0)
  const totalPrice = parseInt(unit || 0, 10) * price

  const resetCardFields = () => {
    reset(
      {
        cardnumber: '',
        cvv: '',
        expirydate: ''
      },
      { keepValues: true }
    )
  }

  const populateCardFields = (selectedOption) => {
    const card = findCardById(cards, selectedOption.split('_')[1])
    if (card) {
      setValue('cardnumber', card.card_number)
      setValue('cvv', card.cvv)
      setValue('expirydate', card.expiry_date)
    }
  }

  const findCardById = (cards, cardId) =>
    cards.find((card) => card.id === cardId)

  const handleInvestmentProcessing = (
    successMessage,
    response = null,
    data = null
  ) => {
    if (response) {
      if (data) {
        saveRecurring({
          cardNumber: data.cardnumber,
          expiryDate: data.expirydate,
          cvv: data.cvv,
          reference: response.reference
        })
      } else {
        saveRecurring({
          card_id: selectedOption.split('_')[1],
          reference: response.reference
        })
      }
    }
    processInvestment({
      investment_id: id,
      user_id: user.id,
      units: unit,
      amount: totalPrice,
      status: 'success'
    })
    onClose()
    toast.success(successMessage)
    window.location.reload()
  }

  const handleRecurringPaymentError = (error) => {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred.'

    if (errorMessage === 'Recurring details not found for the given card ID') {
      toast.warn(
        'Payment details are missing. Redirecting to Paystack to update your payment information.'
      )
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        card: {
          number: watch('cardnumber'),
          cvv: watch('cvv'),
          expiry_month: watch('expirydate').split('/')[0],
          expiry_year: watch('expirydate').split('/')[1]
        },
        amount: totalPrice * 100,
        callback: (response) => {
          handleInvestmentProcessing('Payment successful!', response)
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else {
      toast.error(errorMessage)
    }
  }

  const setupPaystackPayment = ({
    key,
    email,
    amount,
    card,
    callback,
    onClose
  }) => {
    const paystack = window.PaystackPop.setup({
      key,
      email,
      amount,
      card,
      callback,
      onClose
    })
    paystack.openIframe()
  }

  const onSubmit = (data) => {
    if (selectedOption === 'paystack') {
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        amount: totalPrice * 100,
        callback: (response) => {
          saveInvestmentPayment({
            investment_id: id,
            units: data.unit,
            payment_reference: response.reference
          })
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else if (selectedOption?.startsWith('card_')) {
      const card = findCardById(cards, selectedOption.split('_')[1])
      processRecurringPayment({
        card_id: card.id,
        type: 'investment',
        amount: totalPrice,
        investment_id: id,
        units: data.unit
      })
    } else if (
      showCardFields &&
      data.cardnumber &&
      data.expirydate &&
      data.cvv
    ) {
      const [month, year] = data.expirydate.split('/')
      setupPaystackPayment({
        key: paystackPublicKey,
        email: user?.email,
        amount: totalPrice * 100,
        card: {
          number: data.cardnumber,
          cvv: data.cvv,
          expiry_month: month,
          expiry_year: year
        },
        callback: (response) => {
          handleInvestmentProcessing('Payment successful!', response, data)
        },
        onClose: () => toast.error('Payment process was closed.')
      })
    } else {
      toast.error(
        'Please select a payment method or enter complete card details.'
      )
    }
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white w-[90%] max-w-[500px] rounded-lg text-[#444444] shadow-lg p-4 relative'>
        {showCardFields ? (
          <>
            <h1 className='text-black ts w-full center text-center font-[500]'>
              Add new card
            </h1>
            <button
              onClick={() => {
                setSelectedOption(null)
                setShowCardFields(false)
              }}
              className='absolute top-4 right-2 hover:text-gray-700'
            >
              <X size={20} />
            </button>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='grid w-full items-center gap-1.5 ts mt-3'>
                <label htmlFor='email'>Card number</label>
                <input
                  type='number'
                  name='cardnumber'
                  placeholder='0000 0000 0000 0000'
                  {...register('cardnumber', {
                    required: 'Card number is required',
                    minLength: {
                      value: 16,
                      message: 'Card number must be at least 16 digits'
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'Card number must be a number'
                    }
                  })}
                  className='email-input ts'
                  onWheel={(e) => e.target.blur()}
                />

                {errors.cardnumber && (
                  <p className='text-red-500 text-xs'>
                    {errors.cardnumber.message}
                  </p>
                )}
              </div>
              <div className='w-full center gap-4 mt-3'>
                <div className='grid w-[50%] items-center gap-1.5 ts'>
                  <label htmlFor='email'>Expiry Date</label>
                  <input
                    type='text'
                    name='expirydate'
                    placeholder='MM/YY'
                    {...register('expirydate', {
                      required: 'Expiry date is required',
                      pattern: {
                        value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                        message: 'Invalid expiry date format (MM/YY)'
                      }
                    })}
                    className='email-input ts'
                  />
                  {errors.expirydate && (
                    <p className='text-red-500 text-xs'>
                      {errors.expirydate.message}
                    </p>
                  )}
                </div>
                <div className='grid w-[50%] items-center gap-1.5 ts'>
                  <label htmlFor='email'>CVV</label>
                  <input
                    type='text'
                    name='cvv'
                    placeholder='123'
                    maxLength={3}
                    {...register('cvv', {
                      required: 'CVV is required',
                      pattern: {
                        value: /^[0-9]{3}$/,
                        message: 'Invalid CVV format (3 digits)'
                      }
                    })}
                    className='email-input ts'
                  />
                  {errors.cvv && (
                    <p className='text-red-500 text-xs'>{errors.cvv.message}</p>
                  )}
                </div>
              </div>

              <button
                type='submit'
                className={`ts mt-4 ${
                  isDirty && isValid
                    ? 'activebutton'
                    : 'h-[50px] w-full rounded-[8px] bg-darkash text-black'
                }`}
                disabled={!(isDirty && isValid)}
              >
                Continue
              </button>
            </form>

            <div className='pt-2 w-full center gap-3 text-[#888888] mt-3 ts'>
              <h1 className='font-[400] text-center'>
                Paystack would require your card details again to process your
                payment successfully.
              </h1>
            </div>
          </>
        ) : (
          <>
            <h1 className='text-black ts w-full center text-center font-[500]'>
              Invest
            </h1>
            <button
              onClick={onClose}
              className='absolute top-4 right-2 hover:text-gray-700'
            >
              <X size={20} />
            </button>

            <form className='w-full mt-4' onSubmit={handleSubmit(onSubmit)}>
              <div className='flex items-center justify-between'>
                <label htmlFor='unit'>
                  How many units would you like to buy?
                </label>
                <span className='text-sm text-gray-500'>
                  {units} units available
                </span>
              </div>

              <TextInput
                register={register}
                text=''
                placeholder='Input here'
                name='unit'
                errors={errors}
                validation={{
                  required: 'This field is required',
                  min: { value: 1, message: 'Enter at least 1 unit' },
                  max: {
                    value: units,
                    message: `You cannot buy more than ${units} units`
                  },
                  pattern: { value: /^\d+$/, message: 'Enter a valid number' }
                }}
              />
              <div className='w-full flex justify-between items-center ts mt-3'>
                <h1>Price per share</h1>
                <h1>₦{price.toLocaleString()}</h1>
              </div>
              {totalPrice > 0 && (
                <>
                  <div className='w-full flex justify-between items-center ts mt-3'>
                    <h1>Estimated amount</h1>
                    <h1>₦{totalPrice.toLocaleString()}</h1>
                  </div>
                  <h1 className='ts mt-3 text-center font-bold'>
                    Select payment method
                  </h1>

                  <div className='mt-3 w-full gap-3 '>
                    {cards.map((card, index) => (
                      <div
                        key={index}
                        class='mt-2 flex justify-between ps-4 border border-gray-200 rounded dark:border-gray-700'
                      >
                        <div className='flex items-center'>
                          <label
                            for='bordered-checkbox-2'
                            class='flex w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                          >
                            <img
                              alt='img'
                              src={require('../../../../resources/mastercard.png')}
                              className='w-[37px] h-[25px]'
                            />
                            <h1 className='ml-2 left-3 bottom-4 text-black ts'>
                              {' '}
                              **** **** **** {card.card_number.slice(-4)}
                            </h1>
                          </label>
                        </div>

                        <input
                          type='checkbox'
                          id='toggleCardFields'
                          checked={selectedOption === `card_${card.id}`}
                          onChange={() => setSelectedOption(`card_${card.id}`)}
                          class='text-black mt-3 mr-3 w-[15px] h-[15px]'
                        />
                      </div>
                    ))}
                    <div class='mt-2 flex justify-between ps-4 border border-gray-200 rounded dark:border-gray-700'>
                      <div className='flex items-center'>
                        <label
                          for='bordered-checkbox-2'
                          class='flex w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                        >
                          <img
                            alt='img'
                            src={require('../../../../resources/paystack2.png')}
                            className='w-[37px] h-[25px]'
                          />
                          <h1 className='ml-2 left-3 bottom-4 text-black ts'>
                            Pay with paystack{' '}
                          </h1>
                        </label>
                      </div>

                      <input
                        type='checkbox'
                        id='toggleCardFields'
                        checked={selectedOption === 'paystack'}
                        onChange={() => setSelectedOption('paystack')}
                        class='text-black mt-3 mr-3 w-[15px] h-[15px]'
                      />
                    </div>
                    <div class='mt-2 flex justify-between ps-4 border border-gray-200 rounded dark:border-gray-700'>
                      <div className='flex items-center'>
                        <label
                          for='bordered-checkbox-2'
                          class='flex w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                        >
                          <img
                            alt='img'
                            src={require('../../../../resources/card2.png')}
                            className='w-[37px] h-[25px]'
                          />
                          <h1 className='ml-2 left-3 bottom-4 text-black ts'>
                            Add new card{' '}
                          </h1>
                        </label>
                      </div>

                      <input
                        type='checkbox'
                        id='toggleCardFields'
                        checked={selectedOption === 'add_card'}
                        onChange={() => setSelectedOption('add_card')}
                        class='text-black mt-3 mr-3 w-[15px] h-[15px]'
                      />
                    </div>
                  </div>
                </>
              )}
              {/* Conditionally render Proceed button */}
              {unit > 0 && (
                <div className='w-full mt-3'>
                  <button
                    type='submit'
                    className={`ts ${
                      isDirty && isValid
                        ? 'activebutton'
                        : 'h-[50px] w-full rounded-[8px] bg-darkash text-black'
                    }`}
                    disabled={!(isDirty && isValid)}
                  >
                    Invest
                  </button>
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default ModalForUserInvestAccount
